import React from 'react';
import Layout from "../components/layout";
import Helmet from "../components/helmet";

let config = {
  pageTitle: 'Impressum'
}


export default ({ children, ...props }) => {
    return (
      <Layout>
        <Helmet title={config.pageTitle}/>


        <div className="columns">
          <div className="column">

            <h2>Impressum</h2>
          
            <h4>Medieninhaber:</h4>
            <p>
              Wolfgang Engelhardt<br/>
              Im Dörfl 3,
              3100 St. Pölten-Unterwagram<br/>
              Öster­re­ich
            </p>
            <p>
              Unternehmensgegenstand: Lebens- und Sozialberatung
            </p>
            <p>
              +43 664 4533121<br/>
              wolf.​engelhardt@​gmail.​com
            </p>
            <p>
              Wirtschaftskammer Niederösterreich, Personenberatung & Personenbetreuung<br/>
              Fachverband für Mal- und Gestaltungstherapie (fmgt) Wien www.mgt.or.at 
            </p>

            <hr/>

            <h4>Fotos:</h4>
            <p>
              Hans Kogler (<a href="http://www.hans-kogler.at" target="_blank" rel="noopener noreferrer">www.hans-kogler.at</a>)
              <br/>
              Wolfgang Engelhardt
              <br/>
              Melissa Engelhardt
              <br/>
              Tereza Terra
            </p>

            <hr/>

            <h4>Gestaltung und technische Umsetzung:</h4>
            <p>
              Martin Grubinger&nbsp;&nbsp;&times;&nbsp;&nbsp;<a href="https://grooovinger.com" target="_blank" rel="noopener noreferrer">grooovinger.com</a>
            </p>

          </div>
    
        </div>


        {children}
      </Layout>
    )
}
